import $ from 'jquery';
// Set jQuery global
window.$ = window.jQuery = $;
//
import 'popper.js';
import 'bootstrap';
// import 'fancybox';
import 'selectize';
import 'slick-carousel';
// import 'jquery.mmenu';

$(document).ready(function () {
    $("a[href*=\\#]:not([href=\\#])").on('click', function () {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            let target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top - 125 //offsets for fixed header
                }, 1000);
            }
        }
    });
    //Executed on page load with URL containing an anchor tag.
    if ($(location.href.split("#")[1])) {
        let target = $('#' + location.href.split("#")[1]);
        if (target.length) {
            $('html,body').animate({
                scrollTop: target.offset().top - 125 //offset height of header here too.
            }, 1000);
        }
    }

    // Search
    $('.search-icon .btn-link').on('click', function () {
        $(this).toggleClass('active').find('.icon-search, .icon-close').toggleClass('icon-search icon-close');
        $('.input-search').toggleClass('active');
    });

    // Init slick galleries
    $('.slideshow').slick({
        prevArrow: '<button type="button" class="slick-prev"><span class="icon-chevron-thin-left"></span></button>',
        nextArrow: '<button type="button" class="slick-next"><span class="icon-chevron-thin-right"></span></button>',
    });

    $('.card-slider').slick({
        arrows: false,
        dots: true
    });

    // Init selectize on select fields
    $('select').selectize();

    // News filter function - Isotope replacement ↓↓↓
    var $filterValue = null,
        newsItem = $('.news-item');

    if ($('.news-list')) {
        $('.news-list-filter').on('click', 'button', function () {
            $filterValue = $(this).attr('data-filter');

            newsItem.addClass('fadeout');
            $(this).addClass('active').siblings('.active').removeClass('active');
        });

        newsItem.bind('transitionend webkitTransitionEnd', function () {
            if ($(this).hasClass('fadeout')) {
                $(this).hide();
            }

            if (!$filterValue) {
                newsItem.show();
                newsItem.removeClass('fadeout');
            } else {
                newsItem.each(function () {
                    if ($(this).hasClass($filterValue)) {
                        $(this).show();
                        $(this).removeClass('fadeout');
                    }
                });
            }
        });
    }


    // Sticky Header
    var header = document.getElementById('stickyHeader');

    function fixedHeader() {
        if (window.pageYOffset > 500) {
            header.classList.add('sticky');
        } else {
            header.classList.remove('sticky');
        }
    }

    //scroll to top
    var scrollToTopButton = document.getElementById('scrollToTop');
    var bottomOfPage = document.body.scrollHeight;
    var viewHeight = window.innerHeight;

    function scrollToTop() {
        if (window.pageYOffset + viewHeight + 400 > bottomOfPage) {
            scrollToTopButton.classList.add('show');
        } else {
            scrollToTopButton.classList.remove('show');
        }
    }

    $(scrollToTopButton).click(function () {
        $('html, body').animate({scrollTop: 0}, '50');
    });

    window.onscroll = function () {
        scrollToTop();
        fixedHeader();
    };
});