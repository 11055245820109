import './font/icomoon/style.css'
import './styles/main.scss'
import './scripts/main.js'
import './scripts/mmenu.js'
import './scripts/jquery.fancybox.min'

const ctx = require.context('../../components', true, /\.js$/);
ctx.keys().forEach(name=>ctx(name));

// Image import for images that aren't included in CSS
// import './img/footer-icon.svg'