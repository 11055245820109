import $ from "jquery";
import 'jquery.mmenu';

$(()=> {
    var $menu = $(".mmenu").mmenu({
        navbar: {
            title: "Navigation"
        },
        navbars: [{
            position: "top",
            content: ["prev", "title"]
        }],
        "extensions": [
            "position-right",
            "shadow-page"
        ]
    });

    var $icon = $(".hamburger");
    var API = $menu.data("mmenu");

    $icon.on("click", function () {
        API.open();
    });

    if(API) {
        API.bind("open:start", function () {
            setTimeout(function () {
                $icon.addClass("is-active");
            }, 100);
        });

        API.bind("close:start", function () {
            setTimeout(function () {
                $icon.removeClass("is-active");
            }, 100);
        });
    }

    // DEBUG - Show it right away
    // API.open();
});